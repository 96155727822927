/**
 * Local storage keys
 */
const C_PREFIX = 'vulcan.';
const C_LANG = 'vulcan.lang';
const C_TOKEN = 'token';
const C_TOKEN_EXPIRATION_DATE = 'tokenExpirationDate';
const C_ORGANIZATION = 'organizationId';
const C_ORGA_ROLE = 'orgaRole';
const C_PRODUCT = 'product';

// Allocate
let storage = localStorage;

/**
 * Write locale value
 * @param {string} pValue the lang value
 */
export function writeLocale(pValue) {
	storage.setItem(C_LANG, pValue);
}

/**
 * Read locale value
 * @return The lang value or null if not defined
 */
export function readLocale() {
	return storage.getItem(C_LANG);
}

/**
 * Write auth access token value
 * @param {string} pValue the lang value
 */
export function writeAuthToken(pValue) {
	storage.setItem(C_PREFIX + C_TOKEN, pValue);
}

/**
 * Read auth access token value
 * @return The lang value or null if not defined
 */
export function readAuthToken() {
	let value = storage.getItem(C_PREFIX + C_TOKEN);
	return value ? value : null;
}

/**
 * Remove auth access token
 */
export function removeAuthToken() {
	storage.removeItem(C_PREFIX + C_TOKEN);
}

/**
 * Write orga id
 * @param {*} orgaId Orga Id
 */
export function writeOrganizationId(orgaId) {
	storage.setItem(C_PREFIX + C_ORGANIZATION, orgaId);
}

/**
 * Read orga id
 */
export function readOrganizationId() {
	let orgaId = storage.getItem(C_PREFIX + C_ORGANIZATION);
	return orgaId ? orgaId : null;
}

/**
 * Remove organization id
 */
export function removeOrganizationId() {
	storage.removeItem(C_PREFIX + C_ORGANIZATION);
}

/**
 * Write token expiration date
 * @param {*} tokenExpDate token expiration date
 */
export function writeTokenExpirationDate(tokenExpDate) {
	storage.setItem(C_PREFIX + C_TOKEN_EXPIRATION_DATE, tokenExpDate);
}

/**
 * Read token expiration date
 */
export function readTokenExpirationDate() {
	return storage.getItem(C_PREFIX + C_TOKEN_EXPIRATION_DATE);
}

/**
 * Remove organization id
 */
export function removeTokenExpirationDate() {
	storage.removeItem(C_PREFIX + C_TOKEN_EXPIRATION_DATE);
}

/**
 * Stores the role of a user for a certain organization
 * @param {*} role User role
 */
export function writeOrganizationUserRole(role) {
	storage.setItem(C_PREFIX + C_ORGA_ROLE, role);
}

/**
 * Reads the role of a user for a certain organization
 * @returns User role
 */
export function readOrganizationUserRole() {
	return storage.getItem(C_PREFIX + C_ORGA_ROLE);
}

/**
 * Removes from the local storage the role of a user for a certain organization
 */
export function removeOrganizationUserRole() {
	storage.removeItem(C_PREFIX + C_ORGA_ROLE);
}

/**
 * Write current product
 */
export function writeCurrentSimulator(productId) {
	storage.setItem(C_PREFIX + C_PRODUCT, productId);
}

/**
 * Read current product
 */
export function readCurrentSimulator() {
	let currentProductId = storage.getItem(C_PREFIX + C_PRODUCT);
	return currentProductId ? currentProductId : null;
}

export function removeCurrentSimulator() {
	storage.removeItem(C_PREFIX + C_PRODUCT);
}