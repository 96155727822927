import { requestDelete, requestGet, requestPost, requestPut, updateSolrIndex } from '../../../../utils';

// Action constants
export const GET_CLASSES_LIST_SUCCESS = '@@classes/GET_CLASSES_LIST_SUCCESS';
export const GET_CLASSES_LIST_FAILURE = '@@classes/GET_CLASSES_LIST_FAILURE';
export const RESET_CLASSES_LIST = '@@classes/RESET_CLASSES_LIST';

/**
 * Get a specific class
 * @param {*} id Class id
 */
export async function getClassById(id) {
    return await requestGet(`/api/vulcan/clazz/${id}`);

}

/**
 * Get classes' list with the amount of users
 * @param {*} first First element
 * @param {*} count Number of elements requested
 */
export function getClassesList(first, count) {
    return async dispatch => {
        let response;
        try {
            if (count > 0) {
                response = await requestGet(`/api/vulcan/clazz/list/${first}/${count}`);
            } else {
                response = await requestGet('/api/vulcan/clazz/list');
            }
            dispatch({
                type: GET_CLASSES_LIST_SUCCESS,
                payload: response.data
            });

            return response.data.size;

        } catch (error) {
            dispatch({
                type: GET_CLASSES_LIST_FAILURE,
                payload: error
            });
        }
    };
}

// Empty the list of classes from redux
export function emptyClasseList() {
    return dispatch => {
        return dispatch({
            type: RESET_CLASSES_LIST,
            payload: {}
        });
    };
}

/**
 * Get classes' list with the nimbers of users
 */
export async function getClassesListWithUsers() {
    return await requestGet('/api/vulcan/clazz/list/users');
}
/**
 * Create a new class
 * @param {*} clazz Class that will be created
 */
export async function createClass(clazz) {
    return await requestPost('/api/vulcan/clazz', clazz);
}

/**
 * Add users to the class requested
 * @param {*} userClassDto List of object who contains the user id and the class id where he belongs
 */
export async function addUsersToClass(userClassDtoList) {
    await updateSolrIndex();
    return await requestPost('/api/vulcan/clazz/users', userClassDtoList);
}

/**
 *  Delete a class from an organization
 * @param {*} classId Classes id that will be deleted
 */
export async function deleteClass(classeId) {
    return await requestDelete('/api/vulcan/clazz/list', classeId);
}

/**
 * Remove a user from a class
 * @param {*} userClassDto List of object who contains the user id and the class id where he belongs
 */
export async function removeUserFromClass(userClassDtoList) {
    await updateSolrIndex();
    return await requestPut('/api/vulcan/clazz/users', userClassDtoList);

}

/**
 * Update a class
 * @param {*} clazz The class which is updated
 */
export async function updateClass(clazz) {
    await updateSolrIndex();
    return await requestPut('/api/vulcan/clazz', clazz);
}