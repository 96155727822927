// ************************************** //
// GENERAL

// Roles
export const LEARNER = 'LEARNER';
export const TRAINER = 'TRAINER';
export const ADMINISTRATOR = 'ADMINISTRATOR';
export const DISTRIBUTOR = 'DISTRIBUTOR';
export const EDITOR = 'EDITOR';
export const SUPER_ADMINISTRATOR = 'SUPERADMINISTRATOR';

// Subscriptions
export const NO_SUBSCRIPTION = 'NO_SUBSCRIPTION';
export const LIMITED_DURATION = 'LIMITED_DURATION';
export const UNLIMITED = 'UNLIMITED';

// Ressources
export const PRODUCT_RESSOURCE = 'PRODUCT';
export const MODULES_RESSOURCE = 'MODULES';
export const SENSORS_RESSOURCE = 'SENSORS';
export const VARIABLES_RESSOURCE = 'VARIABLES';
export const LEARNING_PATHS_RESSOURCE = 'LEARNINGPATHS';
export const EXERCISES_RESSOURCE = 'EXERCISES';

// Colors
export const DASHBOARD_GREY_COLOR = '#898989';
export const DASHBOARD_PRIMARY_COLOR = '#ff4701';
export const DASHBOARD_BACKGROUND_COLOR = '#f3f3f3';

export const ORANGE_ACTIF_COLOR = '#FF6D29';
export const GREEN_SUCCESS_COLOR = '#96E28A';
export const RED_FAILED_COLOR = '#DA9696';
export const RED_ABORTED_COLOR = '#FF001D';
export const GREY_TODO_COLOR = '#D6D6D6';

// ************************************** //
// PLUGINS
export const VULCAN_PLUGIN_MOODLE_NAME = 'MOODLE';

export const VULCAN_PLUGIN_COMPUTE = 'Mimbus.Vulcan.Plugin.Compute';
export const VULCAN_PLUGIN_STEPS = 'Mimbus.Vulcan.Plugin.Steps';
export const VULCAN_PLUGIN_JSON_PRODUCT_GATEWAY = 'Mimbus.Vulcan.Plugin.JSONProductGateway';


// ************************************** //
// LEARNING CONTENT

// Learning Path
export const LEARNING_PATH_DEFAULT = 'DEFAULT';
export const LEARNING_PATH_CUSTOM = 'CUSTOM';

// Exercise
export const EXERCISE_TYPE_DEFAULT = 'DEFAULT';
export const EXERCISE_TYPE_CUSTOM = 'CUSTOM';

export const EXERCISE_DIFFICULTY_BEGINNER = 'BEGINNER';
export const EXERCISE_DIFFICULTY_ADVANCED = 'ADVANCED';
export const EXERCISE_DIFFICULTY_EXPERT = 'EXPERT';

export const EXERCISE_STATUS_SUCCEED = 'SUCCEED';

// Sensor
export const SENSOR_METHOD_RANGE = 'RANGE';
export const SENSOR_METHOD_TIME_OUTSIDE = 'TIME_OUTSIDE';
export const SENSOR_METHOD_TIME_INSIDE = 'TIME_INSIDE';


// ************************************** //
// LICENSES
// Licenses Status
export const LICENSE_ACTIVE = 'ACTIVE';
export const LICENSE_CREATED = 'CREATED';
export const LICENSE_REVOKED = 'REVOKED';
export const LICENSE_EXPIRED = 'EXPIRED';


// Licenses Status Color
export const GREY_CREATED_COLOR = '#898989';
export const GREEN_ACTIVE_COLOR = '#96E28A';
export const BLUE_INACTIVE_COLOR = '#6690FF';
export const ORANGE_EXPIRING_COLOR = '#DB4D1D';
export const RED_EXPIRED_COLOR = '#FF001D';
export const LIGHT_RED_SUSPENDED_COLOR = '#DA9696';
export const GREY_BANNED_COLOR = '#D6D6D6';

// License Timeline
export const LICENSE_CREATED_COLOR = '#FF6D29';
export const LICENSE_GROUP_CHANGED_COLOR = '#FF6D29';
export const LICENSE_RENEWED_COLOR = '#FF6D29';
export const LICENSE_CLIENT_CHANGED_COLOR = '#FF6D29';
export const LICENSE_REVOKED_COLOR = '#FF001D';
export const LICENSE_MACHINE_ACTIVATED_COLOR = '#96E28A';
export const LICENSE_USAGE_INCREMENTED_COLOR = '#FF6D29';
export const LICENSE_CHECKEDIN_COLOR = '#FF6D29';