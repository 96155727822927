import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';

//
// Antd
//
import antdEnUS from 'antd/lib/locale-provider/en_US';
import antdFrFR from 'antd/lib/locale-provider/fr_FR';
import { readLocale, writeLocale } from '@/utils/localStorage';
import { isNullOrUndefined } from '@/utils';

export const LocaleContext = createContext();

/**
 * Vulcan supported languages
 */
export const vulcanSupportedLanguages = ['fr-FR', 'en-US'];

// antd supported lang
const supportedAntdLocaleData = {
	'en-US': antdEnUS,
	'fr-FR': antdFrFR
};

/**
 * Translation files loader
 */
const messagesLoader = {
	'fr-FR': () => import('./lang/fr-FR.json'),
	'en-US': () => import('./lang/en-US.json')
};

// Retrieves preferred locale
let local = readLocale();

// If no preffered locale, use navigator language
if (!local) {
	local = navigator.language;
}

// Check if language is supported
const languageSupported = vulcanSupportedLanguages.find(l => {
	if (l.includes(local)) return l;
});

if (!isNullOrUndefined(languageSupported)) {
	local = languageSupported;
} else {
	// Not Supported, fallback
	local = 'en-US';
}

// Save locale
writeLocale(local);

// Get the correct antd locale for the ConfigProvider
const localAntdValue = supportedAntdLocaleData[local];

/**
 * Provides localization to the application
 * @param {*} props Component properties
 */
function LocaleProvider(props) {

	const [messages, setMessages] = useState(null);
	const [locale, setLocale] = useState(local);
	const [antdLocaleValue, setAntdLocaleValue] = useState(localAntdValue);


	useEffect(() => {
		messagesLoader[locale]().then(messages => {
			setMessages(messages);
		});
		setAntdLocaleValue(localAntdValue);
	}, [locale, antdLocaleValue]);

	return (
		<LocaleContext.Provider value={{ locale, setLocale }}>
			<ConfigProvider locale={antdLocaleValue}>
				<IntlProvider messages={messages} locale={locale}>
					{messages && props.children}
				</IntlProvider>
			</ConfigProvider>
		</LocaleContext.Provider>
	);
}

LocaleProvider.propTypes = {
	children: PropTypes.any
};

export default LocaleProvider;