import React, { Suspense, lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, BrowserRouter, Switch, withRouter, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import defineAbility from './common/access-control/ability';
import { AbilityContext } from './common/access-control/Can';

// import { Layout } from 'antd';
// const { Content } = Layout;

import { ORGANIZATIONS, APPS, DASHBOARD, EDITOR, USERS_PROFILE } from 'routes/namedRoutes';
import { saveUserInformations } from './components/dashboard/users/requests/UsersActions';
import { getOrganizationbyId, saveUserOrganizations, saveUserRoleForAnOrganization } from './common/requests/organization/OrganizationsActions';
import { isNullOrUndefined, isObjectEmpty } from './utils';
import { readOrganizationId, readOrganizationUserRole, removeOrganizationId } from './utils/localStorage';
import { getPluginsForAnOrganization } from './common/requests/plugins/PluginsAction';
import { useAbility } from '@casl/react';
import LoadingFallback from './common/loading/LoadingFallback';

const VulcanOrganizations = lazy(() => import('./components/organizations/OrganizationPage'));
const VulcanDashboard = lazy(() => import('./components/dashboard/VulcanDashboard'));
const VulcanEditor = lazy(() => import('./components/editor/VulcanEditor'));
const VulcanApps = lazy(() => import('./components/apps/VulcanApps'));
// const VulcanStore = lazy(() => import('./components/store/VulcanStore'));
// const VulcanDistributor = lazy(() => import('./components/distributor/VulcanDistributor'));
const UserProfile = lazy(() => import('./components/profile/ProfilePage'));

/**
 * Application component
 */
function App() {
	// Hooks
	const ability = useAbility(AbilityContext);
	const dispatch = useDispatch();
	const { userInformations } = useSelector(state => state.users);
	const { organizations } = useSelector(state => state.organizations);
	const { list: pluginsList } = useSelector(state => state.plugins);
	const { keycloak } = useKeycloak();

	const [isOrganizationLoading, setIsOrganizationLoading] = useState();

	const currentOrganizationId = readOrganizationId();

	// Set app title
	useEffect(() => { document.title = 'Vulcan'; }, []);

	// Save the user's information and organizations to Redux
	useEffect(() => {

		if (keycloak.authenticated) {
			// No need to save it again if its already in Redux
			if (isObjectEmpty(userInformations)) {
				dispatch(saveUserInformations(keycloak))
					.then(userProfile => {
						if (!organizations.length) {
							setIsOrganizationLoading(true);
							dispatch(saveUserOrganizations(userProfile.id))
								.then(organizationList => {
									/*
										Keycloak Session expired but the organization id is still stored in the local storage
										if a new user connects to the same computer, check if he has this orgnization in his list
									*/
									const currentOrganizationId = readOrganizationId();
									if (!isNullOrUndefined(currentOrganizationId) && !organizationList.some(o => o.id === currentOrganizationId)) {
										removeOrganizationId();
									}

									if (!isNullOrUndefined(currentOrganizationId)) {
										const currentOrganization = organizationList.find(org => org.id === currentOrganizationId);
										const organizationUserRole = currentOrganization.userRole;
										dispatch(saveUserRoleForAnOrganization(organizationUserRole));
									}
								});
						}
					});
			}

			// Update abilities with roles
			defineAbility(readOrganizationUserRole(), ability);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInformations, keycloak.authenticated, organizations.length]);


	// Keep in store the current organization
	useEffect(() => {
		if (keycloak.authenticated) {

			// When we store a new organization id in the local storage
			if (!isNullOrUndefined(currentOrganizationId)) {
				// Save current organization and organization plugins to Redux
				dispatch(getOrganizationbyId(currentOrganizationId))
					.then(() => {
						dispatch(getPluginsForAnOrganization());
					});
			}

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentOrganizationId, keycloak.authenticated]);

	return (
		<Suspense fallback={<LoadingFallback isUsedInApps={false} />}>
			<BrowserRouter >
				<Switch>
					<Route path={ORGANIZATIONS}
						render={() => <VulcanOrganizations loading={isOrganizationLoading} onLoading={setIsOrganizationLoading} />}
					/>
					<Route path={USERS_PROFILE} component={UserProfile} />

					{/* Routes only available after selecting an organization */}
					<Route path='*' render={() => {

						// No organization selected yet
						if (isNullOrUndefined(currentOrganizationId)) {
							return <Redirect to={ORGANIZATIONS} />;
						}

						// Plugins list for the organization not fetched yet
						if (isNullOrUndefined(pluginsList)) {
							return <LoadingFallback />;
						}

						return (
							<Switch>
								<Route path={APPS} component={VulcanApps} />
								<Route path={DASHBOARD} component={VulcanDashboard} />
								<Route path={EDITOR} component={VulcanEditor} />
								{/* <Route path={STORE} component={VulcanStore} /> */}
								{/* <Route path={DISTRIBUTOR} component={VulcanDistributor} /> */}
								<Redirect from="*" to={ORGANIZATIONS} />
							</Switch>
						);

					}} />
				</Switch>
			</BrowserRouter >
		</Suspense>
	);
}

App.propTypes = {
	location: PropTypes.object,
	match: PropTypes.object
};

export default withRouter(App);
