import { GET_ALL_SENSOR_FROM_SIMULATOR_SUCCESS, GET_ALL_SENSOR_FROM_SIMULATOR_FAILURE } from './SensorsActions';
/**
 * Initial state
 */
const initialState = {

};

const handlers = {

    [GET_ALL_SENSOR_FROM_SIMULATOR_SUCCESS]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            sensorsList: payload
        });
    },

    [GET_ALL_SENSOR_FROM_SIMULATOR_FAILURE]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            errorCode: payload
        });
    }
};

export default function sensorsReducer(state = initialState, action) {
    let handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action.payload) };
}