import { requestGet, requestPost, requestPut } from '@/utils';

export const GET_PLUGINS_LIST_IN_PROGRESS = '@@plugins/GET_PLUGINS_LIST_IN_PROGRESS';
export const GET_PLUGINS_LIST_SUCCESS = '@@plugins/GET_PLUGINS_LIST_SUCCESS';
export const GET_PLUGINS_LIST_FAILURE = '@@plugins/GET_PLUGINS_LIST_FAILURE';


/**
 * Gets list of plugins for an organization
 * @returns Request response
 */
export function getPluginsForAnOrganization() {
    return async dispatch => {

        dispatch({ type: GET_PLUGINS_LIST_IN_PROGRESS });

        try {
            const response = await requestGet('/api/vulcan/organization/plugins');
            dispatch({
                type: GET_PLUGINS_LIST_SUCCESS,
                payload: response.data
            });

        } catch (error) {
            dispatch({
                type: GET_PLUGINS_LIST_FAILURE,
                payload: { errorCode: error.code }
            });
        }
    };
}

/**
 * Save the plugin configuration
 * @param {*} pluginInformations Configuration information about the plugin
 */
export async function setConfigurablePlugin(pluginInformations) {
    return await requestPut('/api/vulcan/organization/plugins/informations', pluginInformations);
}

/**
 * Trigger directly a specific plugin
 * @param {Array<object>} pluginList Organization's plugin list
 * @param {string} pluginName Plugin's name you want to trigger
 * @param {object} metadatas Object who contains informations to trigger the plugin (e.g origin, method...)
 */
export async function triggersSpecificPlugin(pluginList, pluginName, metadatas) {

    // Get the plugin wanted
    const plugin = pluginList.find(plugin => plugin.name.includes(pluginName));

    const formatPlugin = {
        id: plugin.id,
        name: plugin.name,
        metadatas: metadatas
    };

    return await requestPost('/api/vulcan/plugin/action', formatPlugin);
}
