export const SAVE_CURRENT_ACTIVE_PATH = '@@navigation/SAVE_CURRENT_ACTIVE_PATH';

/**
 * Store the role of the user
 * @param {*} userRole Role of the user
 */
export function saveCurrentActivePath(activePath) {
    return dispatch => {
        dispatch({
            type: SAVE_CURRENT_ACTIVE_PATH,
            payload: activePath
        });
    };
}