import { removeAuthToken, removeCurrentSimulator, removeOrganizationId, writeAuthToken } from '@/utils/localStorage';
import Keycloak from 'keycloak-js';
import config from '@/config';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

const KLConfig = {
	url: config.keycloak.authServer,
	realm: config.keycloak.realm,
	clientId: config.keycloak.clientId
};

const keycloak = new Keycloak(KLConfig);

/**
 * Initialization options
 */
const initOptions = {
	onLoad: 'login-required',
	pkceMethod: 'S256',
	checkLoginIframe: false
};

function redirectToLogin() {
	removeAuthToken();
	removeCurrentSimulator();
	removeOrganizationId();
	keycloak.login();
}

/**
 * Keycloak events handler
 * @param {*} event Event
 * @param {*} error Error
 */
function eventsHandler(event, error) {

	if (error) {
		console.log(error);
	}

	switch (event) {
		case 'onAuthSuccess':
			writeAuthToken(keycloak.token);
			break;
		case 'onAuthLogout':
			removeAuthToken();
			break;
		case 'onAuthError':
			redirectToLogin();
			console.log(error);
			break;
	}
}

/**
 * React keycloak provider properties
 */
const providerProps = {
	authClient: keycloak,
	initOptions,
	onEvent: eventsHandler,
	onTokens: tokens => writeAuthToken(tokens.token)
};

export {
	keycloak,
	providerProps
};