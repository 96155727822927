import {
	GET_PRODUCTS_ACTIVE_LIST_SUCCESS,
	GET_PRODUCTS_ACTIVE_LIST_FAILURE,
	GET_PRODUCT_ACTIVE_MODULES_LIST_SUCCESS,
	GET_PRODUCT_ACTIVE_MODULES_LIST_FAILURE
} from './SimulatorsActions';

/**
 * Initial state
 */
const initialState = {

	// Dashboard
	activeProducts: {},
	activeModules: {},
	selectedProduct: null

};

const handlers = {

	// Dashboard

	[GET_PRODUCTS_ACTIVE_LIST_SUCCESS]: (previousState, payload) => {
		return Object.assign({}, previousState, {
			activeProducts: payload.list
		});
	},
	[GET_PRODUCTS_ACTIVE_LIST_FAILURE]: (previousState, payload) => {
		return Object.assign({}, previousState, {
			error: payload.errorCode
		});
	},
	[GET_PRODUCT_ACTIVE_MODULES_LIST_SUCCESS]: (previousState, payload) => {
		return Object.assign({}, previousState, {
			activeModules: payload.list
		});
	},
	[GET_PRODUCT_ACTIVE_MODULES_LIST_FAILURE]: (previousState, payload) => {
		return Object.assign({}, previousState, {
			error: payload.errorCode
		});
	}
};

export default function simulatorsReducer(state = initialState, action) {
	let handler = handlers[action.type];
	if (!handler) return state;
	return { ...state, ...handler(state, action.payload) };
}

