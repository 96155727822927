import {
	SAVE_EXERCISE_ID_FOR_A_RESULT_SUCCESS
} from './ExercisesActions';

/**
 * Initial state
 */
const initialState = {
	exerciseId: ''
};

const handlers = {
	// Save exercise id for a result
	[SAVE_EXERCISE_ID_FOR_A_RESULT_SUCCESS]: (previousState, payload) => {
		return Object.assign({}, previousState, {
			exerciseId: payload
		});
	}
};

export default function exercisesReducer(state = initialState, action) {
	let handler = handlers[action.type];
	if (!handler) return state;
	return { ...state, ...handler(state, action.payload) };
}