import { requestGet } from '@/utils';

export const GET_ALL_VARIABLES_FROM_SIMULATOR_SUCCESS = '@@variables/GET_ALL_VARIABLES_FROM_SIMULATOR_SUCCESS';
export const GET_ALL_VARIABLES_FROM_SIMULATOR_FAILURE = '@@variables/GET_ALL_VARIABLES_FROM_SIMULATOR_FAILURE';

/**
 * Retrieves all variables from a simulator
 * @param {*} productId Simulator id
 */
export function getAllVariablesFromASimulator(productId) {
    return dispatch => {
        return requestGet(`/api/vulcan/variable/${productId}/list`)
            .then(response => {
                dispatch({
                    type: GET_ALL_VARIABLES_FROM_SIMULATOR_SUCCESS,
                    payload: { variablesList: response.data }
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_ALL_VARIABLES_FROM_SIMULATOR_FAILURE,
                    payload: { errorCode: error.code }
                });
            });
    };
}