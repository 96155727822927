import React from 'react';
import { createRoot } from 'react-dom/client'; // Import correct pour React 18
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { store } from './store';
import LocaleProvider from './i18n/LocaleProvider';

import defineAbility from './common/access-control/ability';
import { AbilityContext } from './common/access-control/Can';

import { ReactKeycloakProvider as KeycloakProvider } from '@react-keycloak/web';
import { providerProps as keycloakProviderProps } from 'auth/keycloak';
import { readOrganizationUserRole } from './utils/localStorage';

import 'antd/dist/antd.css';
import 'moment/locale/fr';
import './assets/stylesheets/style.less';

const ability = defineAbility(readOrganizationUserRole());

const container = document.getElementById('root');
const root = createRoot(container); // Créer la racine avec createRoot

// Utiliser createRoot pour le rendu
root.render(
	<KeycloakProvider {...keycloakProviderProps}>
		<React.StrictMode>
			<ReduxProvider store={store}>
				<AbilityContext.Provider value={ability}>
					<LocaleProvider>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</LocaleProvider>
				</AbilityContext.Provider>
			</ReduxProvider>
		</React.StrictMode>
	</KeycloakProvider>
);
