/**
 * Centralisation du chargement des variables d'environnement,
 * pour s'assurer que env-config.js est bien chargé avant l’utilisation des variables,
 * avec ajout de valeur par défaut si window._env_?.REACT_APP_* n’est pas défini.
 */

const config = {
    api: {
      vulcan: window._env_?.REACT_APP_VULCAN_API_URL || 'http://localhost:17399',
      licences: window._env_?.REACT_APP_LICENCES_API_URL || 'https://localhost:44316',
      event: window._env_?.REACT_APP_EVENT_API_URL || 'https://mimbus-vulcan-events-dev-webapi.azurewebsites.net'
    },
    keycloak: {
      authServer: window._env_?.REACT_APP_KEYCLOAK_AUTH_SERVER || 'https://beta.vulcan-edu.com/auth/',
      realm: window._env_?.REACT_APP_KEYCLOAK_REALM || 'dev-realm',
      clientId: window._env_?.REACT_APP_KEYCLOAK_CLIENT_ID || 'frontend'
    },
    solr: window._env_?.REACT_APP_SOLR || 'https://open.vulcan-edu.com/search'
  };

  export default config;