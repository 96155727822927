import {
    GET_ALL_VARIABLES_FROM_SIMULATOR_SUCCESS, GET_ALL_VARIABLES_FROM_SIMULATOR_FAILURE
} from './VariablesActions';

/**
 * Initial state
 */
const initialState = {

};

const handlers = {
    [GET_ALL_VARIABLES_FROM_SIMULATOR_SUCCESS]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            variablesList: payload.variablesList
        });
    },

    [GET_ALL_VARIABLES_FROM_SIMULATOR_FAILURE]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            errorCode: payload
        });
    }
};
export default function variablesReducer(state = initialState, action) {
    let handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action.payload) };
}